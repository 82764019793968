import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

// Static files
const LineData = (
  ['', 'top-0', 'right-36', '', '']
) 

const NotFoundPage = () => {
  return (
    <Layout line={LineData}>
      <Seo title="404: Not found" />
      <h1 className="text-3xl sm:text-4xl font-medium mt-4">404: Not Found</h1>
      <p className="text-lg sm:text-xl mb-8">You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}



export default NotFoundPage
